import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Spinner } from 'reactstrap';
import { Overlay } from './components/Overlay';
// import { LoginGuard } from './guards/login-guard';
import { Routes } from './routes';
import './styles/index.scss';

const fallback = (
    <Overlay>
        <Spinner color="white" />
    </Overlay>
);

const root = document.getElementById('root');

// function showLoginMessage() {
//     const overlay = document.createElement('div');
//     overlay.classList.add('overlay');

//     const text = document.createElement('span');
//     text.innerHTML = 'Access Denied';

//     overlay.appendChild(text);

//     root?.appendChild(overlay);
// }

document.cookie =
    'Authorization=eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiI3N2RlOWYwNy01MzhmLTRlNTEtYjAzYi1iYzllYzczZjU4ODUiLCJpc3MiOiJpZHAiLCJhdWQiOiJjaGFubmVsc3VpdGUiLCJzdWIiOiJ0aThtX2NzX2RlbW9fdXNlciIsImRlZmF1bHQiOiJkZWZhdWx0Iiwicm9sZXMiOlsiZGlnaWRlX2ludF90ZWNoX3VzZXIiLCJkaWdpZGVfaW50X3NpbHZlcl8xIl0sImV4cCI6MTY3NTUwNDIxMH0.Ikcw7QgFHiVcE7lAdsK2vY7mi33YlwJ-13mFX223OpKlezs7oGuwQuesJkBKN3tRR8CpOqTSbOPF0I3GOjIGSMOb24HjKKQ-zCExlbZkVYfDO1bAPTR5HUMNPYPMSnEpiAxKm1I5XL_fg5kX1TefzEXHS5Gn0X4yUfNLm3Ew-m_gFH0MsyqvuOLXeuZAD_n6GqxCaAflSuBmS6SK8ZVL3k5CiTMmpNrqf4kdxoOlNV0bHHHI1zoM43LBk54XVTNPB9EJ10TiX8lMgFjTH7_GU-qJnlnQ1ZQ_zCf6HSMlrRat0Nip4BCvFKZ1mrDazo2qUgSKvIyZ7mrhtxkNlkxKqQ';

ReactDOM.render(
    <React.StrictMode>
        <Suspense fallback={fallback}>
            {/* <LoginGuard onFail={showLoginMessage}> */}
            <BrowserRouter>
                <Routes />
            </BrowserRouter>
            {/* </LoginGuard> */}
        </Suspense>
    </React.StrictMode>,
    root
);
