import React, { useEffect, useLayoutEffect, useMemo } from 'react';

interface PredicateGuardProps {
    predicate: boolean | (() => boolean);
    onFail: () => unknown;
}

export const PredicateGuard: React.FC<PredicateGuardProps> = ({
    predicate,
    onFail,
    children,
}) => {
    const [ankered, setAnkered] = React.useState(false);

    const result = useMemo(() => {
        if (!predicate) return false;

        if (typeof predicate === 'function') return predicate();

        return predicate;
    }, [predicate]);

    useEffect(() => {
        if (!result && ankered) onFail();
    }, [result, ankered, onFail]);

    useLayoutEffect(() => setAnkered(true), []);

    if (!result) return null;

    return <>{children}</>;
};
