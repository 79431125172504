import { uniqueId } from 'lodash';
import React, { lazy, useCallback, useEffect, useState } from 'react';
import {
    Route,
    Routes as RouterRoutes,
    useNavigate,
    useSearchParams,
} from 'react-router-dom';
import { Spinner } from 'reactstrap';
import { Overlay } from './components/Overlay';
import { PredicateGuard } from './guards/predicate-guard';
import { createCase, getCase, startVideoOnboarding } from './utils/CaseUtils';

const Intro = lazy(() => import('./pages/Intro'));
const DataStart = lazy(() => import('./pages/data/Start'));
const DataCustom = lazy(() => import('./pages/data/Custom'));
const VideoChat = lazy(() => import('./pages/VideoChat'));
const Mobile = lazy(() => import('./pages/Mobile'));
const Accepted = lazy(() => import('./pages/Accepted'));
const Declined = lazy(() => import('./pages/Declined'));
const HangUp = lazy(() => import('./pages/HangUp'));
const Settings = lazy(() => import('./pages/Settings'));

const LoadingOverlay = (
    <Overlay>
        <Spinner color="white" />
    </Overlay>
);

const createCaseRequest = (data: IPersonalIdentificationData) => ({
    callerId: uniqueId('caller-'),
    personalIdentificationData: data,
});

export const Routes = () => {
    const nav = useNavigate();
    const [params, setParams] = useSearchParams();
    const caseId = params.get('case-id');

    const [videoOnboarding, setVideoOnboarding] = useState(() =>
        Boolean(new URLSearchParams(location.search).get('signing'))
    );
    const [settingsOpened, setSettingsOpened] = useState(false);
    const [settings, setSettings] = useState<ISettings>({
        environment: '',
    });

    const [loading, setLoading] = React.useState(false);
    const [caseData, setCaseData] = useState<IVideoIdentificationCaseDetails>();

    const handleStart = useCallback(
        (withSigning) => {
            setVideoOnboarding(withSigning);

            if (withSigning) nav('/data/custom?signing=true');
            else nav('/data');
        },
        [nav]
    );

    const handleDefaultCase = useCallback(async () => {
        setLoading(true);

        const res = await fetch('/default-data.json');
        const data = await res.json();

        createCase(settings.environment, createCaseRequest(data)).then((c) =>
            setParams({ 'case-id': c.caseId })
        );

        setLoading(false);
    }, [nav]);

    const handleCustomCase = useCallback(() => nav('/data/custom'), [nav]);

    const handleNameDataBack = useCallback(() => {
        nav('/data');
    }, [nav]);

    const handleData = useCallback(
        (
            data: IPersonalIdentificationData,
            environmentUrl: string = settings.environment
        ) => {
            if (videoOnboarding) {
                return startVideoOnboarding(environmentUrl, data).then(() =>
                    nav('/mobile')
                );
            } else {
                return createCase(
                    settings.environment, // We always use the current environment to create cases
                    createCaseRequest(data)
                ).then((c) => setParams({ 'case-id': c.caseId }));
            }
        },
        [nav]
    );

    const handleHangUp = useCallback(() => {
        setCaseData(undefined);
        nav('/');
    }, [nav]);

    const handleCallComplete = useCallback(
        () =>
            nav({
                pathname: '/hang-up',
                search: new URLSearchParams(
                    caseId ? { 'case-id': caseId } : {}
                ).toString(),
            }),
        [nav]
    );

    const handleAccepted = useCallback(() => {
        setCaseData(undefined);
        nav({
            pathname: '/accepted',
            search: new URLSearchParams(
                caseId ? { 'case-id': caseId } : {}
            ).toString(),
        });
    }, [nav]);

    const handleDeclined = useCallback(() => {
        setCaseData(undefined);
        nav({
            pathname: '/declined',
            search: new URLSearchParams(
                caseId ? { 'case-id': caseId } : {}
            ).toString(),
        });
    }, [nav]);

    const reset = useCallback(() => {
        setCaseData(undefined);
        nav('/');
    }, [nav]);

    const hasCase = !!caseData;

    useEffect(() => {
        if (!caseId || hasCase) return;

        nav({ pathname: '/video-chat', search: params.toString() });

        getCase(caseId).then((cd) => {
            setCaseData(cd);
            params.set('case-id', cd.caseId);

            const state = cd.identificationState.state;

            /* eslint-disable indent */
            switch (state) {
                case 'INITIALIZED':
                case 'CLIENT_READY':
                case 'CALL_READY':
                case 'IN_CALL':
                case 'INTERRUPTED':
                    nav({ pathname: '/video-chat', search: params.toString() });
                    break;
                case 'CALL_DONE':
                    nav({ pathname: '/hang-up', search: params.toString() });
                    break;
                case 'VERIFIED':
                    nav({ pathname: '/accepted', search: params.toString() });
                    break;
                case 'FAILED':
                    nav({ pathname: '/declined', search: params.toString() });
                    break;
                default:
                    nav({ pathname: '/' });
                    break;
            }
            /* eslint-enable indent */
        });
    }, [caseId, hasCase]);

    const linkHome = React.useCallback(() => nav('/'), []);

    const openSettings = useCallback(() => setSettingsOpened(true), []);
    const closeSettings = useCallback(() => setSettingsOpened(false), []);

    const handleSettings = useCallback(
        (newSettings) => {
            setSettings(newSettings);
            closeSettings();
        },
        [closeSettings]
    );

    return (
        <>
            {loading && LoadingOverlay}

            <RouterRoutes>
                <Route
                    index={true}
                    element={
                        <>
                            <button id="settings-button" onClick={openSettings}>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="2em"
                                    height="2em"
                                    fill="currentColor"
                                    viewBox="0 0 16 16"
                                >
                                    <path d="M8 4.754a3.246 3.246 0 1 0 0 6.492 3.246 3.246 0 0 0 0-6.492zM5.754 8a2.246 2.246 0 1 1 4.492 0 2.246 2.246 0 0 1-4.492 0z" />
                                    <path d="M9.796 1.343c-.527-1.79-3.065-1.79-3.592 0l-.094.319a.873.873 0 0 1-1.255.52l-.292-.16c-1.64-.892-3.433.902-2.54 2.541l.159.292a.873.873 0 0 1-.52 1.255l-.319.094c-1.79.527-1.79 3.065 0 3.592l.319.094a.873.873 0 0 1 .52 1.255l-.16.292c-.892 1.64.901 3.434 2.541 2.54l.292-.159a.873.873 0 0 1 1.255.52l.094.319c.527 1.79 3.065 1.79 3.592 0l.094-.319a.873.873 0 0 1 1.255-.52l.292.16c1.64.893 3.434-.902 2.54-2.541l-.159-.292a.873.873 0 0 1 .52-1.255l.319-.094c1.79-.527 1.79-3.065 0-3.592l-.319-.094a.873.873 0 0 1-.52-1.255l.16-.292c.893-1.64-.902-3.433-2.541-2.54l-.292.159a.873.873 0 0 1-1.255-.52l-.094-.319zm-2.633.283c.246-.835 1.428-.835 1.674 0l.094.319a1.873 1.873 0 0 0 2.693 1.115l.291-.16c.764-.415 1.6.42 1.184 1.185l-.159.292a1.873 1.873 0 0 0 1.116 2.692l.318.094c.835.246.835 1.428 0 1.674l-.319.094a1.873 1.873 0 0 0-1.115 2.693l.16.291c.415.764-.42 1.6-1.185 1.184l-.291-.159a1.873 1.873 0 0 0-2.693 1.116l-.094.318c-.246.835-1.428.835-1.674 0l-.094-.319a1.873 1.873 0 0 0-2.692-1.115l-.292.16c-.764.415-1.6-.42-1.184-1.185l.159-.291A1.873 1.873 0 0 0 1.945 8.93l-.319-.094c-.835-.246-.835-1.428 0-1.674l.319-.094A1.873 1.873 0 0 0 3.06 4.377l-.16-.292c-.415-.764.42-1.6 1.185-1.184l.292.159a1.873 1.873 0 0 0 2.692-1.115l.094-.319z" />
                                </svg>
                            </button>

                            <Intro onStart={handleStart} />

                            <Overlay in={settingsOpened}>
                                <Settings
                                    settings={settings}
                                    onSubmit={handleSettings}
                                    onCancel={closeSettings}
                                />
                            </Overlay>
                        </>
                    }
                />
                <Route path="data">
                    <Route
                        index={true}
                        element={
                            <DataStart
                                onDefault={handleDefaultCase}
                                onCustom={handleCustomCase}
                            />
                        }
                    />
                    <Route
                        path="custom"
                        element={
                            <DataCustom
                                onBack={handleNameDataBack}
                                onData={handleData}
                            />
                        }
                    />
                </Route>
                <Route path="mobile" element={<Mobile onComplete={reset} />} />
                <Route
                    path="video-chat"
                    element={
                        <PredicateGuard predicate={!!caseId} onFail={linkHome}>
                            {!caseData && LoadingOverlay}
                            {caseData && (
                                <VideoChat
                                    videoCase={caseData}
                                    onExit={handleHangUp}
                                    onComplete={handleCallComplete}
                                />
                            )}
                        </PredicateGuard>
                    }
                />
                <Route
                    path="hang-up"
                    element={
                        <PredicateGuard predicate={!!caseId} onFail={linkHome}>
                            {caseId && (
                                <HangUp
                                    caseId={caseId}
                                    onAccepted={handleAccepted}
                                    onDeclined={handleDeclined}
                                />
                            )}
                        </PredicateGuard>
                    }
                />
                <Route
                    path="accepted"
                    element={<Accepted onComplete={reset} />}
                />
                <Route
                    path="declined"
                    element={<Declined onComplete={reset} />}
                />
            </RouterRoutes>
        </>
    );
};
