import axios, { AxiosError, AxiosResponse } from 'axios';

export enum IdentifcationDecision {
    ACCEPTED = 'ACCEPTED',
    REJECTED = 'REJECTED',
}

export enum ImageTag {
    MRZ = 'MRZ',
    ID_FRONT = 'ID_FRONT',
    ID_BACK = 'ID_BACK',
    PASSPORT_FRONT = 'PASSPORT_FRONT',
    PASSPORT_BACK = 'PASSPORT_BACK',
    PORTRAIT = 'PORTRAIT',
    PROOF_OF_RESIDENCE = 'PROOF_OF_RESIDENCE',
}

export enum Tenant {
    OAK_1 = 'oak-1',
    '93DD' = '93dd',
    WHITE_1 = 'white-1',
    SILVER_2 = 'silver-2',
}

export enum Environment {
    PROD = 'prod',
    TST = 'tst',
    INT = 'int',
}

export class CaseError extends Error {
    constructor(m: string) {
        super(m);
        Object.setPrototypeOf(this, CaseError.prototype);
    }
}

export async function tryLogin(): Promise<boolean> {
    const loginData = new FormData();
    loginData.append(
        'username',
        process.env.REACT_APP_CASE_HOST_USERNAME || ''
    );
    loginData.append(
        'password',
        process.env.REACT_APP_CASE_HOST_PASSWORD || ''
    );

    try {
        const response = await fetch('/login', {
            method: 'POST',
            body: loginData,
            redirect: 'manual',
        });

        return response.status === 200;
    } catch (error) {
        console.log(error);
    }

    return false;
}

async function withLogin(
    request: () => Promise<AxiosResponse>
): Promise<AxiosResponse> {
    let attempts = 0;
    // eslint-disable-next-line no-constant-condition
    while (true) {
        try {
            const response = await request();
            return response;
        } catch (error) {
            if (error instanceof Error) {
                if ((error as AxiosError).response?.status === 401) {
                    await tryLogin();
                }
                if ((error as AxiosError).response?.status === 404) {
                    throw new CaseError('invalid case id');
                }
            }
            attempts++;
            if (attempts > 1) {
                const timeout: number = 200 * attempts;
                await new Promise((resolve) => setTimeout(resolve, timeout));
            }
            if (attempts > 3) {
                throw new Error('cannot login');
            }
        }
    }
}

export async function getCase(
    caseId: string
): Promise<IVideoIdentificationCaseDetails> {
    const response = await withLogin(() =>
        axios.get(`/delegate/digital-identification/v1/video-cases/${caseId}`)
    );
    const videoCase: IVideoIdentificationCaseDetails = response.data;
    return videoCase;
}

export async function createCase(
    environmentPrefix: string,
    caseRequest: IVideoIdentificationRequest
): Promise<IVideoIdentificationCaseDetails> {
    const response = await withLogin(() =>
        axios.post(
            `${environmentPrefix}/delegate/digital-identification/v1/video-cases`,
            caseRequest
        )
    );

    const caseResponse: IVideoIdentificationCase = response.data;

    return await getCase(caseResponse.caseId);
}

export async function startVideoOnboarding(
    environmentPrefix: string,
    data: IPersonalIdentificationData
) {
    const response = await fetch(
        `${environmentPrefix}/delegate/ident-process/v3/start`,
        {
            method: 'POST',
            headers: {
                'X-TNT': data.tenant,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                name: 'generic-ident',
                vars: {
                    email: data.contact.email,
                    firstName: data.firstName,
                    lastName: data.lastName,
                    dateOfBirth: data.dateOfBirth,
                    gender: data.salutation === 'Mr' ? 'MALE' : 'FEMALE',
                    'domicileAddress.streetNumber': '1',
                    'domicileAddress.street': 'Bahnhofstrasse',
                    'domicileAddress.city': 'Zurich',
                    'domicileAddress.postalCode': '8001',
                    'domicileAddress.country': 'CH',
                    nationality: data.nationality,
                    mobileNumber: getMobileNumber(data.contact.phoneNumber),
                    mobileNumberAreaCode: getMobileAreaCode(
                        data.contact.phoneNumber
                    ),
                    idCheck: data.idCheck,
                    eSignature: data.eSignature,
                    waiverApproved: true, // should be always true
                    correspondenceLanguage: data.correspondenceLanguage,
                },
            }),
        }
    );
    if (response.ok) {
        return response;
    }
    throw new Error(
        `Error ${response.status}: For some reason, it was not possible to start the identification process please try again`
    );
}

function getMobileNumber(phoneNumber: string): string {
    if (phoneNumber.startsWith('+')) return phoneNumber.substring(3);

    if (phoneNumber.startsWith('0')) return phoneNumber.substring(1);

    return phoneNumber;
}

function getMobileAreaCode(phoneNumber: string): string {
    if (phoneNumber.startsWith('+')) return phoneNumber.substring(0, 3);

    return '+41';
}
