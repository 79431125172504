import React from 'react';
import { Fade, FadeProps } from 'reactstrap';
import classnames from 'classnames';

interface OverlayProps extends FadeProps {
    overlayClassName?: string;
}

export const Overlay: React.FC<OverlayProps> = ({
    children,
    overlayClassName,
    ...props
}) => (
    <Fade {...props}>
        {children}
        {props.in && (
            <div className={classnames('overlay', overlayClassName)}>
                <div className="overlay-body">{children}</div>
            </div>
        )}
    </Fade>
);
